
export class ReportParameter
{
  public labels?: any[] = null;
  public name?: string = "";
  public nullable?: boolean = false;
  public prompt?: string = "";
  public values?: any[] = [];
  public DefaultValue: any;
}


export class ActivityByPropertyReportData 
{
  public ActivityId = 0;
  public ClientName = "";
  public Product = "";
  public PropertyId = 0;
  public PropertyName = "";
  public CategoryId = 0;
  public CategoryName = "";
  public StationId = 0;
  public StationName = "";
  public Status = "";
  public StatusId = 0;
  public StartDate: Date;
  public EndDate: Date;
  public IsSold = false;
  public OnHold = false;
  public ClientContact = "";
}

export class DigitalReportData 
{
  public CreationMonthName = "";
  public CreationMonth = 0;
  public CreationYear = 0;
  public StationId = 0;
  public StationName = "";
  public HomeStationName = "";
  public ClientName = "";
  public Product = "";
  public PropertyId = 0;
  public PropertyName = "";
  public CategoryId = 0;
  public CategoryName = "";
  public ProjectManager = "";
}

export class RevenueReportData 
{
  public ActivityId = 0;
  public ClientName = "";
  public Product = "";
  public PropertyId = 0;
  public PropertyName = "";
  public CategoryId = 0;
  public CategoryName = "";
  public StationId = 0;
  public StationName = "";
  public StartDate: Date;
  public EndDate: Date;
  public IsSold = false;
  public ClientContact = "";
  public BookingId = "";
  public Revenue = 0;
}


export class ReportParameters 
{
  public ReportName = "";
  public ReportTitle = "";
  public StartDate: Date;
  public EndDate: Date;
  public PropertyTypeIds: number[] = [];
  public StationIds: number[] = [];
  public CategoryIds: number[] = [];
  public PropertyIds: number[] = [];
  public Statuses: string[] = [];
  public PropertyId = 0;
  public CategoryId = 0;
}

export class ReportSource 
{
  public report: string;
  public parameters: any;
  public parametersAreaVisible: boolean;
}

export class ActivityStatus
{
  public Name = "";
  public Value = 0;
}

export class ReportListItem
{
  public name = "";
  public value = "";
  public show = false;
}
