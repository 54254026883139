import { Category, Property, PropertyType, Station } from "../calendars/calendars.models";
import { ReportParameters } from "../reports/reports.models";

export class EventCategory
{
  public Id = 0;
  public Name = "";
  public ColourName = "";
  public Colour = "";
  public SortOrder = 0;
  public Disabled = false;
  public ChangedByUserId = 0;
  public Created: Date;
  public Deleted: Date;
  public LastUpdated: Date;
}

export class ProductCategory
{
  public Id = 0;
  public ProductCategoryName = "";
  public SortOrder = 0;
  public Disabled = false;
  public ChangedByUserId = 0;
  public LastUpdated: Date;
}

export class DayPart
{
  public Id = 0;
  public DayPartName = "";
  public DayPartLabel = "";
  public SortOrder = 0;
  public Disabled = false;
  public ChangedByUserId = 0;
  public LastUpdated: Date;
}

export class AccessType
{
  public Id = 0;
  public Name = "";
  public AccessMask = 0;
  public Level = 0;
  public Value = 0;
}

export class Permission
{
  public Id = 0;
  public Name = "";
  public AccessTypeId = 0;
  public SortOrder = 0;
}

export class RolePermission
{
  public RoleId = 0;
  public Permissions: Permission[];
  public SortOrder = 0;
}

export class OnlineDependencyType
{
  public Id = 0;
  public Name = "";
  public SortOrder = 0;
  public Disabled = false;
  public ChangedByUserId = 0;
  public LastUpdated: Date;
}

export class NotifyGroup
{
  protected static SInit = (() =>
  {
    NotifyGroup.prototype.Id = 0;
    NotifyGroup.prototype.Name = "";
    NotifyGroup.prototype.InformIfSubmittedForSale = false;
    NotifyGroup.prototype.InformIfOffSubmittedForSale = false;
    NotifyGroup.prototype.WarnFirstRightRefusalCountdown = false;
    NotifyGroup.prototype.InformIfFirstRightRefusalExpires = false;
    NotifyGroup.prototype.InformIfOnHoldExpires = false;
    NotifyGroup.prototype.InformFirstDayAircheckDue = false;
    NotifyGroup.prototype.InformFinalPcrDue = false;
    NotifyGroup.prototype.InformDropDead = false;
    NotifyGroup.prototype.InformDropDeadWarning = false;
    NotifyGroup.prototype.InformCreditLineDue = false;
    NotifyGroup.prototype.InformAudioDueToAdOps = false;
    NotifyGroup.prototype.InformCreditLineChanged = false;
    NotifyGroup.prototype.InformIfConfirmed = false;
    NotifyGroup.prototype.InformIfOnHold = false;
    NotifyGroup.prototype.InformConfirmedActivityDisabled = false;
    NotifyGroup.prototype.InformConfirmedActivityDateRangeChanged = false;
    NotifyGroup.prototype.InformActivitiesImportErrors = false;
    NotifyGroup.prototype.InformActivitiesInterfaceEvents = false;
    NotifyGroup.prototype.InformRatesInterfaceEvents = false;
    NotifyGroup.prototype.InformClientsInterfaceEvents = false;
    NotifyGroup.prototype.InformClientCategoriesInterfaceEvents = false;
    NotifyGroup.prototype.InformUsersInterfaceEvents = false;
    NotifyGroup.prototype.SortOrder = 0;
    NotifyGroup.prototype.Disabled = false;
    NotifyGroup.prototype.ChangedByUserId = 0;
  })();

  public Id: number;
  public Name: string;
  public NotifyGroupType: NotifyGroupType;
  public InformIfSubmittedForSale: boolean;
  public InformIfOffSubmittedForSale: boolean;
  public WarnFirstRightRefusalCountdown: boolean;
  public InformIfFirstRightRefusalExpires: boolean;
  public InformIfOnHoldExpires: boolean;
  public InformFirstDayAircheckDue: boolean;
  public InformFinalPcrDue: boolean;
  public InformDropDead: boolean;
  public InformDropDeadWarning: boolean;
  public InformCreditLineDue: boolean;
  public InformAudioDueToAdOps: boolean;
  public InformCreditLineChanged: boolean;
  public InformIfConfirmed: boolean;
  public InformIfOnHold: boolean;
  public InformConfirmedActivityDisabled: boolean;
  public InformConfirmedActivityDateRangeChanged: boolean;
  public InformActivitiesInterfaceEvents: boolean;
  public InformActivitiesImportErrors: boolean;
  public InformRatesInterfaceEvents: boolean; 
  public InformClientsInterfaceEvents: boolean;
  public InformClientCategoriesInterfaceEvents: boolean;
  public InformUsersInterfaceEvents: boolean;
  public SortOrder: number;
  public Disabled: boolean;
  public ChangedByUserId: number;
  public LastUpdated: Date;
}

export class NotifyGroupType
{
  public Id = 0;
  public Name = "";
  public SortOrder = 0;
  public Disabled = false;
  public ChangedByUserId = 0;
  public LastUpdated: Date;
}

export class NotifyGroupMember
{
  public Id = 0;
  public Station: Station;
  public NotifyGroup: NotifyGroup;
  public NotifyGroupType: NotifyGroupType;
  public Category: Category;
  public Property: Property;
  public PropertyType: PropertyType;
  public Email = "";
  public Mobile = "";
  public Disabled = false;
  public ChangedByUserId = 0;
  public LastUpdated: Date;
}

export class Job
{
  public Id = 0;
  public Title = "";
  public Description = "";
  public JobTypeId = 0;
  public JobTypeName = "";
  public JobDocumentTypeId = 0;
  public JobDocumentTypeName = "";
  public JobActionId = 0;
  public JobActionName = "";
  public Target = "";
  public Frequency = 0;
  public ReportParameters: ReportParameters;
  public Recipients = "";
  public StartDate: Date;
  public EndDate: Date;
  public StartTime: Date;
  public EndTime: Date;
  public StartTimeZone = "";
  public EndTimeZone = "";
  public RecurrenceRule = "";
  public RecurrenceExceptions = ""
  public Disabled = false;
  public ChangedByUserId = 0;
  public Created: Date;
  public Deleted: Date;
  public LastUpdated: Date;
}

export class JobAction
{
  public Id = 0;
  public Name = "";
}

export class JobType
{
  public Id = 0;
  public Name = "";
}

export class JobDocumentType
{
  public Id = 0;
  public Name = "";
}
