import { Injectable, OnDestroy } from '@angular/core';
import { CommonService } from '../../shared/services/common.service';
import { DataHandlerService } from '../../shared/services/data-handler.service';

@Injectable({ providedIn: 'root' })
export class AppConfig implements OnDestroy
{
  public config: any;


  constructor(private dataHandler: DataHandlerService, private commonService: CommonService)
  {
  }

  public load()
  {
    return this.getConfigurationSettings();
  }


  ngOnDestroy()
  {

  }


  getConfigurationSettings()
  {
    return this.dataHandler.getHttpPromise<any>(`/assets/appsettings.json`, false, false);
  }

}
