import { Subject } from 'rxjs';
import { OnDestroy, Directive } from '@angular/core';

@Directive()
export class BaseComponent implements OnDestroy
{
  public ngUnsubscribe: Subject<void> = new Subject<void>();

  ngOnDestroy(): void
  {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
